<script setup lang="ts">
import type { SidebarFooterProps } from '@design-system/components/Organisms/Sidebar/OrganismsSidebarFooter.props'
import type { OrganismsSidebarHeaderProps } from '@design-system/components/Organisms/Sidebar/OrganismsSidebarHeader.props'
import type { LoginSidebarProps } from './LoginSidebar.props'
import { submitForm } from '@formkit/core'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

defineProps<LoginSidebarProps>()

const { ts } = useI18n()
const route = useRoute()
const { myAccount, isHome, getCanonicalUrl } = useRouteHelper()
const { myAccountTabsWithoutForYou } = useShortcuts()
const { dispatchAccountPopupLoginEvent } = useGAAccountEvents()

const {
  LOGIN_FORM_ID,
  isLogged,
  isRegistration,
  loginEmail,
  loginEmailIsValid,
  emailExistOnAuth0,
  socialLoginConnection,
  loginCheckEmail,
  loginCleanCredentials,
  logout,
} = useLogin()

const sidebarEmailLogin = ref('')
const { registrationSuccessful, REGISTRATION_FORM_PERSONAL_INFO_ID } =
  useRegistration()
const { isLoading, success } = useFormKitState([
  LOGIN_FORM_ID,
  REGISTRATION_FORM_PERSONAL_INFO_ID,
])

const { isDialogOpen } = useDialog(SIDEBAR_IDS.loginSidebar)
const { isDialogOpen: isNestedSidebarOpen } = useDialog(
  SIDEBAR_IDS.loginResetPassword
)

const { customer } = await useCustomer()

const headerProps = computed<OrganismsSidebarHeaderProps>(() => ({
  titleText: isLogged.value
    ? ts('myAccount.logged.hello') + ' ' + customer!.value.firstName
    : ts('myAccount.login.armaniAccount'),
  actionText: isLogged.value ? ts('myAccount.logged.logout') : undefined,
  hasCloseButton: !isLogged.value,
  actionIcon: isLogged.value ? 'exit' : undefined,
}))

const footerProps = computed<SidebarFooterProps | undefined>(() => {
  // not logged & not registered
  if (loginEmailIsValid.value && !isLogged.value && !isRegistration.value) {
    if (socialLoginConnection.value) return

    return {
      primaryText: ts('myAccount.login.loginBtn'),
      isPrimaryLoading: isLoading.value,
      isPrimarySuccess: success.value,
    }
  }

  // is logged
  if (isLogged.value) {
    const path = getCanonicalUrl(myAccount)
    return {
      primaryText: ts('myAccount.logged.goToYourAccount'),
      isPrimaryLink: true,
      primaryLinkTo: path,
    }
  }

  // not logged & registration successful
  if (!isLogged.value && registrationSuccessful.value) {
    return undefined
  }

  // not logged & doing registration
  if (!isLogged.value && isRegistration.value) {
    return {
      primaryText: ts('myAccount.registration.ctaSignUp'),
      isPrimaryLoading: isLoading.value,
      isPrimarySuccess: success.value,
    }
  }
})

const handlePrimaryClick = async () => {
  if (!loginEmailIsValid.value) return

  if (!isRegistration.value) {
    if (!emailExistOnAuth0.value) {
      await loginCheckEmail(sidebarEmailLogin.value)
    } else {
      submitForm(LOGIN_FORM_ID)
    }
  } else if (isRegistration.value) {
    submitForm(REGISTRATION_FORM_PERSONAL_INFO_ID)
  }
}

const closeSidebarHandler = () => {
  if (isRegistration.value || registrationSuccessful.value) return

  loginCleanCredentials()
}

const handleLogout = () => {
  logout()
  closeSidebarHandler()
}

const handleEmail = (value: string) => {
  if (isDialogOpen.value) {
    sidebarEmailLogin.value = value
  }
}

onBeforeUnmount(() => {
  // clean credentials before unmounting the sidebar
  // (navigating to a path with a different layout)
  loginCleanCredentials()
})

watch(
  () => route.path,
  () => {
    // clean credentials when navigating to a different path
    loginCleanCredentials()
  }
)
</script>

<template>
  <div>
    <OrganismsSidebarSlide
      :id="SIDEBAR_IDS.loginSidebar"
      :header-props="headerProps"
      :footer-props="footerProps"
      force-global-theme
      :force-open="isNestedSidebarOpen"
      :blur-background="isHome"
      @action-button-click="handleLogout"
      @primary-click="handlePrimaryClick"
      @sidebar-closed-after-leave="closeSidebarHandler"
    >
      <template #body>
        <div>
          <div v-if="!isLogged">
            <LoginForm v-if="!isRegistration" @email="handleEmail($event)" />
            <LoginRegistration
              v-else
              :is-thank-you-page="isThankYouPage"
              @email="handleEmail($event)"
            />
          </div>
          <div v-else class="flex flex-col gap-10">
            <div class="flex items-center gap-2">
              <AtomsShortcut
                v-for="shortcut in myAccountTabsWithoutForYou"
                :key="shortcut.text"
                :link="shortcut.link"
                size="S"
                bg-color="offWhite"
                is-uppercase
                class="flex-1"
                @click="dispatchAccountPopupLoginEvent(shortcut.text)"
              >
                <template #icon>
                  <component :is="shortcut.icon" aria-hidden="true" />
                </template>
                <template #text>
                  <p>{{ shortcut.text }}</p>
                </template>
              </AtomsShortcut>
            </div>
            <div>
              <MyAccountUpcomingOrders />
            </div>
            <div>
              <MyAccountWishlist />
            </div>
          </div>
          <LoginResetPassword
            v-if="!isRegistration"
            :forgotten-password="loginEmail"
          />
        </div>
      </template>
    </OrganismsSidebarSlide>
  </div>
</template>
